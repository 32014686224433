









































































import {Vue, Component} from 'vue-property-decorator';
import {SingleItem} from "@/handlers/interfaces/ContentUI";
import User from "@/models/User";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import Rules from "@/services/tool/Rules";
import Handler from "@/handlers/Handler";
import HeadquarterManagerService from "@/services/HeadquarterManagerService";

@Component({})
export default class CreateHeadquarterManagerDialog extends Vue {

  get lang() { return getModule(LangModule).lang }
  get rules() { return Rules }

  loading: boolean = false
  headquarterManager: SingleItem<User> = { item: new User() }

  name: string = ""
  lastname: string =""
  email: string = ""
  password: string = ""
  passwordMatch: string = ""
  commercialName: string = ""

  async createHeadquarterManager() {
    await Handler.getItem(this, this.headquarterManager, () =>
        HeadquarterManagerService.create(this.email, this.name, this.lastname, this.password, this.commercialName)
    )
    this.$emit("refresh")
    this.$emit("close")
  }

}
